<template>
  <div>
    <Header></Header>
    <div class="jsfn1">
      <img class="jsfnA1" :src="baseUrl+technologyEnabling.mainDrawingSrc" alt="" />
      <div class="jsfnB1">{{technologyEnabling.title}}</div>
      <div class="jsfnC1">{{technologyEnabling.eTitle}}</div>
      <div class="jsfnD1">
        &emsp;<div v-html="technologyEnabling.content"></div>
      </div>
    </div>
    <div class="body">
      <div v-for="(item,index) in classList">
        <div>
          <div class="cpszh">{{ item.title }}</div>
          <div class="bodyTitle">
            <div class="product">{{item.eTitle}}</div>
            <!-- <div class="more">
              <div class="gdal">查看详情</div>
              <div>></div>
            </div> -->
          </div>
        </div>
        <div style="width: 1140px;margin-left: 30px" v-html="item.introduce.replaceAll('/dev-api',baseUrl)" class="ql-editor" >
        </div>
      </div>

<!--      <div>-->
<!--        <div class="cpszh">执法监督预警管理平台</div>-->
<!--        <div class="bodyTitle">-->
<!--          <div class="product">LAW ENFORCEMENT</div>-->
<!--          <div class="more">-->
<!--            <div class="gdal">查看详情</div>-->
<!--            <div>></div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="fontA2">-->
<!--        &emsp;&emsp;执法监督预警管理平台是然茜科技打造的一款用于针对巩固完善执法规范化建设，推进法治建设的软件平台。该系统集成了人脸识别、语音提醒、车辆识别、人数统计等多种AI算法为一体，通过高性能的数据检索和分析引擎对大数据进行快速的检索、比对和分析，实现对接警、处警、办案、执行、回访、统计分析等执法活动的全流程、全过程、全方位、全覆盖监督管理，以及动态化、自动化、智能化的执法问题预警、问题报警、分析监测、警示纠错、督办整改。-->
<!--      </div>-->
<!--      <div>-->
<!--        <img class="jsfnr2" src="@/assets/jsfnr2.png" alt="" />-->
<!--      </div>-->
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
import {getTechnologyEnabling, getTechnologyEnablingClassList} from "../../api/open";
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      enablingId:null,
      technologyEnabling:{},
      baseUrl:process.env.VUE_APP_BASE_API,
      classList:[]
    };
  },
  watch: {
    '$route' (to, from) { //监听URL地址栏参数变化
      if (this.$route.query.enablingId) {
        this.enablingId = this.$route.query.enablingId
        this.getById()
        this.getClassList()
      }
    }
  },
  mounted() {
    this.enablingId = this.$route.query.enablingId
    this.getById()
    this.getClassList()
  },
  methods: {
    getById(){
      getTechnologyEnabling({id:this.enablingId}).then(res=>{
        this.technologyEnabling = res.data
      })
    },
    getClassList(){
      getTechnologyEnablingClassList({enablingId:this.enablingId}).then(res=>{
        this.classList = res.data
      })
    }
  },
};
</script>

<style scoped>
/deep/ .ql-size-large{
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 40px;
}
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.gdal {
  margin-right: 4px;
  font-size: 14px;
  color: #7d7d7d;
}
.jsfnr1 {
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 20px 32px;
  line-height: 40px;
}
.fontA2 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 24px 24px 0 32px;
  line-height: 40px;
}
.jsfnr2 {
  margin: 24px 32px 0 32px;
}
.jsfn1 {
  position: relative;
}
.jsfnA1 {
  width: 100%;
}
.jsfnB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
.jsfnD1 {
  position: absolute;
  top: 360px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 40px;
  letter-spacing: 1px;
}
</style>
